.under {
  z-index: 2;
}

.top {
  z-index: 1;
  transform: translate(0, -100%);
}

.tblToolTipCell {
  position: relative;
}

.tblToolTipCell:hover span.tblToolTipComment {
  display: block;
}

.firstCol {
  width: 15%;
  text-align: center;
  font-size: 20px;
  transform: rotate(-90deg);
}

.firstRow {
  height: 70px;
}

.firstRow th {
  transform: rotate(-90deg);
  font-size: 12px;
  font-weight: normal;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  width: max-content;
}

.sliderTicks {
  height: 70px;
  justify-content: space-between;
}

/* .sliderTicks p{
    transform: rotate(-90deg);
    font-size: 12px;
    font-weight: normal;
    width: max-content;
} */
.sliderTicks div {
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 3px;
  height: 15px;
  background: lightgray;
  line-height: 5px;
}

.sliderTicks div p {
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 0px;
  background: lightgray;
  height: 15px;
  margin: 35px 0px 0px 10px;
  transform: rotate(-90deg);
}

.subRow {
  height: 220px;
  border-bottom: 1px solid black;
  padding: 1px;
}

.tblToolTipComment {
  display: none;
  position: absolute;
  z-index: 100;
  border: 1px;
  background-color: rgb(38, 38, 38);
  border-style: solid;
  border-width: 1px;
  border-color: white;
  padding: 3px;
  color: white;
  top: 100px;
  left: 0x;
  font-size: smaller;
}

@media (max-width:757px) {
  .firstCol {
    font-size: 12px;
  }

  tr.subRow {
    height: 100px;
  }

  #curve_chart {
    width: 100%;
    height: auto;
  }

}




/*////////////////////////*/
/*Style simulation slider*/
/*//////////////////////*/

/* Get rid of the default crap*/
.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background: darkgray;
  outline: none;
  opacity: 1;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: darkgray;
  cursor: pointer;
}

/* Create new classes to be added with JQuery to color the thumbs*/
.vl::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #802000;
  cursor: pointer;
}

.l::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ff9f80;
  cursor: pointer;
}

.m::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ffff80;
  cursor: pointer;
}

.h::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #99e699;
  cursor: pointer;
}

.vh::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #1e7b1e;
  cursor: pointer;
}

.usrSel {
  display: inline-flex;
  height: 10px;
  width: 100%;
  padding: 5px 3px 5px 0px;
}

.under {
  z-index: 2;
}

.top {
  z-index: 1;
  transform: translate(0, -100%);
}

/* 
.indic {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
} */

.indic::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #04AA6D;
  cursor: pointer;
}

.indic::-moz-range-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #04AA6D;
  cursor: pointer;
}

.chart-container-class {
  background-color: transparent;
}

.game-cell {
  background-color: transparent;
}