.slick-dots li.slick-active button::before{
    color: #192F59 !important;
    font-size: 12px;
}

.slick-dots li button::before{
    color: #000 !important;
    font-style: 1rem !important;
    top: 1rem !important;
    font-size: 12px;
}

.slick-prev:before, .slick-next:before{
    color: gray !important;
    font-size: 12px;

}