@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, div, span, h1, h2, h3, h4, h5, h6, p, a, img, ul, li {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-family: 'Poppins', sans-serif;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* styles.css */
.dark-mode {
  background-color: #1f1f1f;
  color: #ffffff;
}

.dark-mode a, .dark-mode p, .dark-mode span{
  color: white;
}

.dark-mode .deskripsi {
  color: black;
}

.active-line {
  height: 3px;
  background-color: #6366F1;
}

.game-cell{
  font-size: 16px;
  text-align: start;
}
thead{
  font-size: 16px;
  text-align: start;
}
/* Custom CSS for the table */
.google-visualization-table-table {
  border-collapse: collapse;
  width: 100%;
  color: #1f1f1f;

}

.google-visualization-table-th {
  border: 1px solid #ccc;
  padding: 18px;
  text-align: left;
  color: #1f1f1f;
}

.google-visualization-table-td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
  color: #1f1f1f;
}

.google-visualization-table-th {
  background-color: transparent;
  font-weight: bold;
  color: #1f1f1f;

}

.google-visualization-table-td-number {
  padding: 8px;
  text-align: center;
  color: #1f1f1f;

}

.google-visualization-table-td-label {
  font-weight: bold;
  color: #1f1f1f;

}

.google-visualization-table-tr {
  padding: 15px;
}
